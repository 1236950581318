import React from "react"
import * as Icon from "react-feather"
import { graphql } from "gatsby"

import CaseDetail from "../../components/Shared/casedetails"

const seoTitle = "Consolidated Audit Trail (CAT) "
const pageTitle = "Consolidated Audit Trail (CAT)"
const subTitle = "Banking and Capital Markets"
const para =
  "Atyeti’s implementation manages the day-today audit tasks from the back office to the front office and integrates seamlessly with existing in-house systems. Atyeti is using Gresham CTC as the tool to facilitate this functionality. The solution leverages leading-edge technology to simplify processes, boost efficiency and reduce operational costs."
const blockquote = `"Consolidated Audit Trail tracks orders throughout their life cycle allowing regulators to efficiently track activity."`

const cardItems = [
  {
    id: 1,
    heading: `Business Solution`,
    listItems: (
      <>
        <li>
          <Icon.Square />
          <b>Gresham CTC</b>: Atyeti’s solution built upon the Gresham CTC
          platform develops, implements and maintains a consolidated audit trail
          that collects and accurately identifies every order, cancellation,
          modification and trade execution for all exchange-listed equities and
          options across all U.S. markets.
        </li>
        <li>
          <Icon.Square /> <b>Regulatory Reporting</b>: The domain specific
          actions are required by each regulatory need. Each action is
          abstracted and transformed by composable steps into each unique
          workflow, accurately submitting regulatory reports for all CAT related
          data.
        </li>
        <li>
          <Icon.Square />
          <b>Governance:</b> The goal is creating reusable ingestion,
          preparation, and aggregation services that are primarily focused on
          data management and managing the data lifecycle.
        </li>
      </>
    ),
  },
  {
    id: 2,
    heading: `Technical Solution `,
    listItems: (
      <>
        <p>
          <b>Hybrid Cloud Data Sync:</b> Sync between private cloud and Azure
          (feeds ingestion, data transfer, storage, validation).
        </p>
        <p>
          <b>Data Processing:</b> Auto-scaling cluster is used for batch
          processing utilizing Azure PaaS offerings.
        </p>
        <p>
          <b>Identity Management:</b>Implementation of Centrify, Azure AD, and
          Azure Key Vault
        </p>
      </>
    ),
  },
  {
    id: 3,
    heading: `Result`,
    listItems: (
      <>
        <p>
          <b>Regulatory Compliance:</b> Exception Management, validation and
          reporting framework for a daily volume of 30M + trades a day.
        </p>
        <p>
          <b> UI:</b> Easy-to-use dashboards which allow users to quickly
          identify, monitor, and manage errors impacting their reports.
          Allocation using rule based auto categorization engine.
        </p>
        <p>
          <b> ETL:</b> Strong enrichment capabilities, data validation.
        </p>
        <p>
          <b>Reporting:</b> Single platform to view your CAT reports
        </p>
        <p>
          <b>Reconciliation:</b> Ability to manage exceptions on a timely basis.
        </p>
      </>
    ),
  },
]
const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
export const query = graphql`
  query ConsolidateQuery {
    casedetailsJson(slug: { eq: "consolidated-audit-trail" }) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
